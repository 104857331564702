<template>
  <div>
    <div class="layoutHeader">
      <div class="container">
        <h2 class="logo">
          <a href="javascript:;">{{ brandName }}</a>
        </h2>
        <div class="slogan">
          <!-- 宁夏融资担保集团招投标保函系统 -->
        </div>
        <div class="hotline">
          <!-- <span>客服热线</span><strong>0563-2020451</strong> -->
          <span>客服热线</span><strong>0575-88620523</strong>
        </div>
      </div>
    </div>
    <div class="layoutMain">
      <router-view />
    </div>
    <div class="layoutFooter">
      Copyright&nbsp;©&nbsp;2023-2025&nbsp;&nbsp;&nbsp;&nbsp;{{ brandName }}&nbsp;&nbsp;&nbsp;&nbsp;All
      Rights Reserved&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href="https://beian.miit.gov.cn"
        >浙ICP备2022033143号-1</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicLayout",
  data() {
    return {
      brandName: process.env.VUE_APP_BRAND,
      tel: process.env.VUE_APP_TEL
    };
  }
};
</script>

<style lang="less" scope>
@deep: ~">>>";
.container {
  width: 1180px;
  margin: 0 auto;
}
.layoutHeader {
  background-color: #fff;
  .container {
    height: 90px;
    overflow: hidden;
  }
  .logo {
    margin-top: -8px;
    float: left;
    a {
      display: inline-block;
      // width:133px;
      width: 506px;
      height: 100px;
      // background:url('../assets/image/logo2.jpg') no-repeat center center;
      background: url("../assets/img/logo4.png") no-repeat center center;
      // background-size:50%;
      background-size: 500px 50px;
      overflow: hidden;
      text-indent: -9999px;
    }
  }
  .slogan {
    margin: 35px 0 0 -20px;
    float: left;
    color: #999;
    font-size: 16px;
  }
  .hotline {
    margin-top: 30px;
    float: right;
    color: #999;
    span {
      vertical-align: middle;
    }
    strong {
      vertical-align: middle;
      margin-left: 10px;
      font-size: 22px;
      color: #007ce2;
    }
  }
}

.layoutMain {
  background: url(./../assets/image/main-bg-top.png) top center no-repeat,
    url(./../assets/image/main-bg-bottom.png) bottom center no-repeat;
  background-size: contain;
  background-color: #f5f6fa;
  padding: 30px 0;
  min-height: 830px;
}
.layoutFooter {
  padding: 20px 0;
  background-color: #10284c;
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
 color: #F9FBFC;
  a{
 color: #F9FBFC;

  }
}
</style>
