import Vue from 'vue';
import VueRouter from 'vue-router';
import BasicLayout from '@/layouts/BasicLayout';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: BasicLayout,
    redirect: '/insure',
    children: [
      {
        path: '/insure',
        name: 'Insure',
        component: () => import('../views/insure'),
      },
      {
        path: '/insure/basicDoor',
        name: 'BasicDoor',
        component: () => import('../views/insure/components/BasicDoor.vue'),
      },
      {
        path: '/insureConfirm',
        name: 'insureConfirm',
        component: () => import('../views/insureConfirm'),
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('../views/order'),
      },
      {
        path: '/history',
        name: 'history',
        component: () => import('../views/history'),
      },
      {
        path: '/insurePay', // 支付页面
        name: 'insurePay',
        meta: {
          isHide: 1,
        },
        component: () => import('../views/insurePay'),
      },
      {
        path: '/pay', // 支付页面
        name: 'pay',
        meta: {
          isHide: 1,
        },
        component: () => import('../views/pay'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
