
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { message, Modal, Steps, notification } from 'ant-design-vue';
import Antd from 'ant-design-vue/es';
import 'ant-design-vue/dist/antd.css';
import 'normalize.css/normalize.css';
import './global.less'; // global style

Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.config.productionTip = false;
Vue.use(Modal);
Vue.use(Steps);
Vue.use(Antd);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
